<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs3 text-left>
            <span class="mainHeader">Dashboard</span>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
        <v-card
          tile
         class="pa-4" elevation="0"
          :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
          :flat="$route.name == 'Dashboard' ? true : false"
        >
        <v-layout wrap>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Sales</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10>₹ 0.00 </v-flex>
            <v-flex xs12 class="tst2" text-right>Rupees</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Profit</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10>₹ 0.00</v-flex>
            <v-flex xs12 class="tst2" text-right>Rupees</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 sm4 md4 pr-4 py-6>
        <v-card class="pa-4 rounded-xl profitlossshadow">
          <v-layout wrap pb-4>
            <v-flex xs12 class="tst2" text-left pl-2>Total Purchase</v-flex>
          </v-layout>
          <v-layout wrap pb-2>
            <v-flex xs12 class="dashtile" pl-10>₹ 0.00</v-flex>
            <v-flex xs12 class="tst2" text-right>Rupees</v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 py-2>
        <v-layout wrap>
          <v-flex xs12 md3 text-left>
            <span class="mainHeader">LATEST ACTIVITIES</span>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12 sm8 md8 pr-4 pb-5 pb-md-0>
        <v-card class="pa-2 rounded-xl profitlossshadow">
          <v-layout wrap>
            <v-flex xs12 pa-4>
              <div id="chart">
                <apexchart
                  type="area"
                  height="350"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <v-flex xs12 md4 sm4 pr-4>
        <v-card class="pa-2 rounded-xl profitlossshadow">
          <v-layout wrap pa-4>
            <v-flex xs12 class="tst2" text-left py-2>Gold Rate</v-flex>
          </v-layout>
          <v-layout wrap py-4>
            <v-flex xs12 class="dashtile" pl-10
              >₹ 0.00 <span class="subhead4">/gm</span></v-flex
            >
          </v-layout>
          

          <v-layout wrap pa-4>
            <v-flex xs12 class="tst2" text-left py-2>Silver Rate</v-flex>
          </v-layout>
          <v-layout wrap py-4>
            <v-flex xs12 class="dashtile" pl-10
              >₹ 0.00 <span class="subhead4">/gm</span></v-flex
            >
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-dialog
      v-model="queryPopUpDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs'
          ? '100vw'
          : $vuetify.breakpoint.name == 'sm'
          ? '60vw'
          : $vuetify.breakpoint.name == 'md'
          ? '50vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '40vw'
          : '30vw'
      "
    >
      <v-card tile color="#FFFFFF">
        <v-layout wrap justify-center pa-2>
          <v-flex xs12 text-left pa-4>
            <span class="mainHeader2">BOARDING RATE</span>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> THANKAM Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    autofocus
                    placeholder="0"
                    class="my-text-field"
                    prefix="₹"
                    v-model="thankamRate"
                    type="number"
                    solo
                    flat
                    style="font-size: 30px"
                    dense
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> GOLD Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    prefix="₹"
                    v-model="gold"
                    type="number"
                    style="font-size: 30px"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    flat
                    dense
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> SILVER Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    v-model="silverRate"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    prefix="₹"
                    style="font-size: 30px"
                    flat
                    dense
                    height="60px"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> PLATINUM Per gm </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    style="font-size: 30px"
                    prefix="₹"
                    v-model="platinumRate"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    flat
                    height="60px"
                    dense
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-flex xs12 sm6 pa-4>
            <v-card>
              <v-layout wrap pa-4 justify-center>
                <v-flex xs12 py-2 class="mainhead2"> DIAMOND </v-flex>
                <v-flex xs8 text-center>
                  <v-text-field
                    prefix="₹"
                    style="font-size: 30px"
                    v-model="diamond"
                    type="number"
                    solo
                    placeholder="0"
                    class="my-text-field"
                    dense
                    flat
                    hide-details
                    height="60px"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-card>
          </v-flex>
          <v-spacer></v-spacer>
          <v-flex xs3 text-right pa-4 align-self-end>
            <v-btn
              height="45px"
              width="80px"
              color="#3F053C"
              class="buttons1"
              dark
              block
              @click="EditRate()"
              >Save</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import store from "./../../store";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      gold: "",
      thankamRate: "",
      silverRate: "",
      platinumRate: "",
      diamond: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      queryPopUpDialog: false,
      username: null,
      name: null,
      address: null,
      phone: null,
      email: null,

      series: [
        {
          name: "Profit",
          data: [31, 40, 28, 51, 42, 109, 100, 30, 50, 78, 89, 80],
        },
        {
          name: "Loss",
          data: [11, 32, 45, 32, 34, 52, 41, 56, 45, 65, 76, 56],
        },
      ],
      chartOptions: {
        colors: ["#731EFF", "#F036DE"],
        chart: {
          height: 350,
          type: "area",
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
        },
        xaxis: {
          type: "datetime",

          categories: [
            "01",
            "02",
            "03",
            "04",
            "05",
            "06",

            "07",
            "08",
            "09",
            "10",
            "11",
            "12",
          ],
        },
        tooltip: {
          x: {
            format: "dd/MM/yy ",
          },
        },
      },

    };
  },
  mounted() {
    this.userinfo();
  },
  methods: {
    validateInput() {
      if (!this.email) {
        this.msg = "Please provide email";
        this.showsnackbar = true;
        return;
      } else if (!this.name) {
        this.msg = "Please provide name";
        this.showsnackbar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please provide phone";
        this.showsnackbar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please provide username";
        this.showsnackbar = true;
        return;
      } else if (!this.address) {
        this.msg = "Please provide address";
        this.showsnackbar = true;
        return;
      } else {
        this.addAdmin();
      }
    },
    userinfo() {
      axios({
        method: "GET",
        url: "/user/info",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: localStorage.getItem("id"),
        },
      })
        .then((response) => {
          // console.log("menu-dashbord");
          if (response.data.status) {
            localStorage.setItem("GSTstatus", response.data.data.taxType);
            // var check = response.data.goldrateadded;
            // if (check == false) {
            //   console.log("check===-=", check);
            //   this.queryPopUpDialog = true;
            //   store.commit("queryPopUpDialog", true);
            // }
          } 
          else {
            console.log("router");
            store.commit("sessionOut", true);
            return;
          }
        })
        .catch((err) => {
          var msg = err;
          console.log(msg);
          // store.commit("sessionOut", true);
        });
    },
    getBording() {
      console.log("3");
      this.appLoading = true;
      axios({
        url: "/rate/view",
        method: "GET",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          date: this.billDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true){
          this.headData = response.data.data;
          store.commit("headData", response.data.data);
          this.diamond = response.data.data.diamond;
          localStorage.setItem("diamond", response.data.data.diamond);
          this.goldRate = response.data.data.goldRate;
          localStorage.setItem("goldRate", response.data.data.goldRate);
          this.platinumRate = response.data.data.platinumRate;
          localStorage.setItem("platinumRate", response.data.data.platinumRate);
          this.silverRate = response.data.data.silverRate;
          localStorage.setItem("silverRate", response.data.data.silverRate);
          this.thankamRate = response.data.data.thankamRate;
          localStorage.setItem("thankamRate", response.data.data.thankamRate);
        }
          else{
            console.log("true");
            this.msg = response.data.msg;
            this.showsnackbar = true;
            localStorage.removeItem("diamond");
          localStorage.removeItem("goldRate");
          localStorage.removeItem("platinumRate");
          localStorage.removeItem("silverRate");
          localStorage.removeItem("thankamRate");
          }
        })
        .catch((err) => {
          //   this.ServerError = true;
          console.log(err);
        });
    },
    EditRate() {
      axios({
        url: "/rate/add",
        method: "POST",
        data: {
          goldRate: this.gold,
          silverRate: this.silverRate,
          platinumRate: this.platinumRate,
          diamond: this.diamond,
          date: this.date,
          thankamRate: this.thankamRate,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = response.data.msg;
            this.showsnackbar = true;
            this.queryPopUpDialog = false;
            this.getBording();
            location.reload();
            // this.goldrate = null;
            // this.silverrate = null;
            // this.platinumrate = null;
            // this.diamondrate = null;
            this.date = null;
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
  },
};
</script>
